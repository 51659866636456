@use "@dvag/design-system/scss/mediaquery";

dx-container {
  /*padding-top: 72px;
  padding-bottom: 72px;*/
}

.spacing {
  margin: 32px 0 32px;
}

@for $i from 1 through 10 {
  $i: $i * 8;
  .spacing-#{$i} {
    margin: #{$i}px 0 #{$i}px;
  }
}

@for $i from 1 through 10 {
  $i: $i * 8;
  .padding#{$i} {
    padding: #{$i}px 0 #{$i}px;
  }
}

@for $i from 1 through 10 {
  $i: $i * 8;
  .margin-horizontal-#{$i} {
    margin: 0 #{$i}px 0 #{$i}px;
  }
}

.container-padding {
  padding: 32px 0 32px;
}

.padding56 {
  padding: 56px 0 56px;
}

.mt-0 {
  margin-top: 0;
}

.mt-16 {
  margin-top: 16px;
}

.mt-32 {
  margin-top: 32px;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-56 {
  margin-top: 56px;
}

.mt-80 {
  margin-top: 80px;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-48 {
  margin-bottom: 48px;
}

.pt-0 {
  padding-top: 0;
}

.pt-80 {
  padding-top: 80px;
}

.pb-48 {
  padding-bottom: 48px;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

.text-editmode {
  color: #f00;
}

.container-headline {
  margin-bottom: 8px;
  z-index: 1;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
